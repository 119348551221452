@import '../../../../scss/theme-bootstrap';

.product-grid {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  //overflow: hidden;
  &__content {
    @include pie-clearfix;
    //max-width: $max-width-large;
    width: 100%;
    margin: 0 auto 1px;
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    width: 100%;
    position: relative;
    &::after {
      position: absolute;
      width: 100%;
      top: auto;
      bottom: 0;
      #{$ldirection}: 0;
      height: 6px;
      content: ' ';
    }
    &.item-active::after {
      background: $color-gray;
    }
  }
  &--small-cols-2,
  &--small-cols-3 {
    .product-grid__item {
      float: $ldirection;
    }
  }
  &--small-cols-2 .product-grid__item {
    width: 50%;
  }
  &--small-cols-3 .product-grid__item {
    width: 33.333%;
  }
  min-height: 480px;
  margin-bottom: 40px;
  @include breakpoint($landscape-up) {
    margin-bottom: 50px;
    &__item {
      float: none;
      width: 33.33%;
      display: inline-flex;
      position: relative;
      &--slide {
        min-height: 645px;
      }
    }
    &--medium-cols-1 .product-grid__item {
      width: auto;
      float: none;
    }
    &--medium-cols-2 .product-grid__item {
      width: 50%;
    }
    &--medium-cols-3 .product-grid__item {
      width: 33.333%;
    }
    &--medium-cols-4 .product-grid__item {
      width: 25%;
    }
  }
  &--carousel {
    display: block;
    & .slick-prev,
    & .slick-next {
      top: 0;
      margin-top: 135px;
    }
    & .slick-prev {
      #{$ldirection}: 0;
      @include breakpoint($landscape-up) {
        #{$ldirection}: 40px;
      }
    }
    & .slick-next {
      #{$rdirection}: 0;
      @include breakpoint($landscape-up) {
        #{$rdirection}: 40px;
      }
    }
    & .slick-slide {
      min-height: 600px;
      float: $ldirection;
    }
    .product-carousel {
      display: none; // Prevent FOUC
      &.slick-initialized {
        display: block;
      }
    }
  }
}
